.list {
  margin: 24px -32px; /* Negative margin should be removed */

  .list-item {
    display: flex;
    min-height: 74px;
    padding: 16px 44px 16px 24px;
    border-top: 0.5px solid #e3e7eb;
    position: relative;

    &:last-child {
      border-bottom: 0.5px solid #e3e7eb;
    }

    .list-item-content {
      display: flex;
      align-items: center;

      .ic {
        margin-right: 12px;
      }
    }

    .list-arrow {
      width: 44px;
      height: 100%;
      right: 0px;
      position: absolute;
      top: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #979da6;
    }
  }
}
