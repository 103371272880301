@import "styles/variables.scss";

.waiting-room {
  background-color: #fff !important;

  .avatar {
    width: 72px;
    height: 72px;

    @media (min-width: $tablet) {
      width: 112px;
      height: 112px;

      img {
        width: 100%;
      }
    }
  }

  .avatar-village {
    position: relative;
  }

  .background {
    position: absolute;
    margin-top: 200px;
    z-index: -1;
  }

  .gray-pill {
    display: inline-block;
    background-color: $gray-5;
    color: $gray-30;
    font-size: 0.875rem;
    border: none;
    padding: 4px 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
  }

  .purple-pill {
    display: inline-block;
    background-color: #f0eef8;
    color: #4e38a6;
    font-size: 0.875rem;
    border: none;
    padding: 4px 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
  }

  .green-pill {
    display: inline-block;
    background-color: #d7f2e4;
    color: #108247;
    font-size: 0.875rem;
    border: none;
    padding: 4px 14px;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
  }

  .v-logo-overlap {
    height: 32px;
    width: 32px;
    position: absolute;
    bottom: -1px;
    right: -2px;
    z-index: 1;
  }
}
