.tippy-tooltip {
  color: #24282e; /* gray-90 */
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(36, 40, 46, 0.2);
}

.tippy-tooltip--small {
  padding: 1rem;
  font-size: 1rem;
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.transparent-theme [x-circle] {
  background-color: rgb(255, 255, 255);
}
