#env-banner {
  &.local {
    background-color: #00c14c;
  }

  &.development {
    background-color: #ffdd57;
  }

  &.alpha,
  &.uat {
    background-color: #f14668;
  }

  #env-name {
    font-weight: bold;
  }
}
