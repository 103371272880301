@import "../../../styles/variables.scss";

a {
  color: #0080a3;
}

a:hover {
  color: #00bbdccc;
}

.appointment-ended-messsage {
  margin-bottom: 108px;
}

.post-call-icon {
  width: 68px;
  height: 68px;
}

.post-call-title {
  color: $gray-90;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin: 16px 8px;
}

.post-call-subtitle {
  color: $gray-80;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  margin: 16px 8px;
}

@media screen and (min-width: $tablet) {
  .post-call-icon {
    width: 96px;
    height: 96px;
  }

  .post-call-title {
    font-size: 24px;
  }
}
