.d-flex {
  display: -webkit-box;
  display: flex;

  &.flex-wrap {
    -webkit-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  &.flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }

  &.flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }

  &.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }

  &.flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }

  &.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  &.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  &.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  &.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  &.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  &.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  &.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  &.align-items-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    align-items: center !important;
  }

  &.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  &.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  &.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
}
