.card {
  box-shadow: 0 2px 6px rgba(23, 51, 91, 0.1);
  border-radius: 8px;

  .card-content {
    padding: 24px;
  }

  .card-footer {
    padding: 0 24px 24px;
  }
}

