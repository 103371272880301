@import "./variables.scss";
@import "./button.scss";
@import "./drawer.scss";
@import "./flexbox_layout.scss";
@import "./fonts.scss";
@import "./card.scss";
@import "./input.scss";
@import "./banner.scss";
@import "./list.scss";

.has-background-light {
  background-color: $light !important;
}

.has-text-primary {
  color: $primary !important;
}

.has-text-secondary {
  color: $secondary !important;
}

.button {
  border-radius: 8px;
  padding: 10px 17px 10px 17px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;

  &.is-text.no-decoration {
    text-decoration: none;
  }

  &.is-primary {
    background-color: $button-color;

    &:hover {
      background: $button-color-hover;
    }

    &.is-disabled,
    &:disabled {
      background: #f0f2f3;
      color: #abb1b9;
    }
  }

  &.is-medium {
    font-size: 1.25rem;
  }

  &.is-rounded {
    border-radius: 28px;
  }

  &.is-text {
    background-color: transparent;
    color: $button-color;
    text-decoration: none;
  }
}

.hero {
  &.is-secondary {
    background-color: $secondary;

    .subtitle {
      color: $white;
    }
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 1.25rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.b-n {
  border: none !important;
}

/*
    General styling
*/
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
}

body,
button,
input,
select,
textarea {
  font-family: "Gotham", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

html, 
body,
#root {
  overflow: initial !important;
}

html,
body,
.fullheight {
  min-height: 100%;
  height: 100%;
}
td:not([align]), th:not([align]) {
  text-align: left;
}

table td:not([align]), table th:not([align]) {
  text-align: left;
}

.fullheight-body {
  min-height: calc(100% - 102px);
  height: calc(100% - 102px);
}

.scrollable {
  overflow: auto;
}

.has-vcentered {
  align-items: center;
}

.has-centered {
  justify-content: center;
}

.is-valign-middle {
  vertical-align: middle !important;
}

::placeholder {
  color: #9299A2 !important;
  opacity: 1;
}

::-webkit-input-placeholder,
::-ms-input-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  /* Internet Explorer 10-11, Microsoft Edge */
  color: #ccc !important;
}

.navbar {
  box-shadow: 0 1px 9px -4px #00436f;
}

#logo {
  max-height: 100%;
  height: 64px;
}

.fit-content {
  max-width: fit-content;
}

.pointer {
  cursor: pointer;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

p {
  margin-bottom: 12px;
}

hr {
  background-color: #E3E7EB;
  height: 0.5px;
  margin: 24px 0px;
}

.modal-background {
  background-color: rgba(47, 59, 77, 0.6);
}

*[role="button"] {
  cursor: pointer;
}

.is-underlined {
  text-decoration: underline;
}

.field:not(:last-child) {
  margin-bottom: 1.5rem;
}
