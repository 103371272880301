$checkbox-color: #0080a3;

/* The container */
.checkbox {
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid $checkbox-color;
  border-radius: 2px;
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 4.5px;
  top: 0.5px;
  width: 6px;
  height: 10.5px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #f2f8f9;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: $checkbox-color;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark::after {
  display: block;
}
