@import "../../../../../styles/variables.scss";

@media only screen and (max-width: $mobile) {
  .wait-screen {
    align-items: initial !important;
    top: 85px;

    &.disconnected-room {
      .avatar-village,
      .waiting-room-name {
        display: none;
      }
    }
  }
}
