.download-app {
  background-color: #f2f8f9;
  margin: 0 -1.25rem -1.25rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1.25rem;

  .download-ios {
    padding: 10px;
  }

  .download-android {
    img {
      max-width: 154px;
    }
  }
}
