@import "./styles/generic.scss";
@import "./styles/variables.scss";

.App-link {
  color: #61dafb;
}

img.is-horizontal-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 50%;
}

// Font styles for entire app
.gray-5 {
  color: $gray-5;
}

.gray-40 {
  color: $gray-40;
}

.gray-60 {
  color: $gray-60;
}

.gray-70 {
  color: $gray-70;
}

.gray-80 {
  color: $gray-80;
}

.gray-90 {
  color: $gray-90;
}

.ios-subtitle-15-semibold {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.ios-body-1-regular {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.web-body-1-book {
  font-size: 16px !important;
  font-weight: 350 !important;
}

.web-body-2-book {
  font-size: 14px !important;
  font-weight: 350 !important;
}

.is-size-7 {
  font-size: 14px !important;
  line-height: 18px;
}

@media screen and (max-width: $mobile) {
  .text-h5-to-h4-medium {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 31px !important;
  }

  .text-h6-to-h5-medium {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

@media screen and (min-width: $tablet) {
  .text-h5-to-h4-medium {
    font-size: 24px !important;
    font-weight: 500 !important;
  }

  .text-h6-to-h5-medium {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
}

// utility button colors
.button.is-utility {
  background-color: $gray-5;
  border-color: transparent;
  color: $gray-80;
}

.button.is-utility:hover,
.button.is-white.is-hovered {
  background-color: $gray-10;
  border-color: transparent;
  color: $gray-80;
}

.button.is-utility:focus,
.button.is-utility.is-focused {
  background-color: $gray-10;
  border-color: transparent;
  color: $gray-80;
}

.button.is-utility:active,
.button.is-utility.is-active {
  background-color: $gray-20;
  border-color: transparent;
  color: $gray-80;
}

.vertical-line {
  display: inline-block;
  border-left: 1px solid #ccc;
  margin: 0 16px;
  height: 2.5em;
  color: $gray-5;
}
