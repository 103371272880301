@import "../styles/variables.scss";

.message.is-info {
  background-color: $bg-blue-30;
  color: $gray-80;
  padding: 12px 12px 12px 40px;
  position: relative;
  margin: 16px;
  max-width: 371px;
  text-align: left;
  border: 1px solid #deeff2;
  border-radius: 8px;

  .icon-container {
    position: absolute;
    z-index: 1;
    left: 11px;
    top: 15px;
  }

  .message-header,
  .message-body {
    background-color: $bg-blue-30;
    color: $gray-80;
    font-weight: 400;
    padding: 0;
    border: none;
  }

  @media only screen and (max-width: 767px) {
    .message-header,
    .message-body {
      font-size: 0.875rem;
    }
  }
}
