.drawer-modal,
.drawer-sidebar {
  position: absolute;
  top: 30px;
  background-color: white;
  width: 100%;
  max-width: 700px;
  min-height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.drawer-sidebar {
  max-width: 300px;
  border-radius: 0;
  left: 0;
  top: 0;

  &.drawer-md {
    max-width: 600px;
  }

  &.drawer-lg {
    max-width: 900px;
  }

  &.drawer-xl {
    max-width: 1040px;
  }
}

@media (max-width: 768px) {
  .drawer-modal {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.css-3pxmmn {
  z-index: 1000 !important;
}
