@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 100;
  src: local('Gotham Thin'), local('Gotham-Thin'), url('/assets/fonts/gotham/Gotham-Thin.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 100;
  src: local('Gotham ThinItalic'), local('Gotham-ThinItalic'), url('/assets/fonts/gotham/Gotham-ThinItalic.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 200;
  src: local('Gotham XLight'), local('Gotham-XLight'), url('/assets/fonts/gotham/Gotham-XLight.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 200;
  src: local('Gotham XLightItalic'), local('Gotham-XLightItalic'), url('/assets/fonts/gotham/Gotham-XLightItalic.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: local('Gotham Light'), local('Gotham-Light'), url('/assets/fonts/gotham/Gotham-Light.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 300;
  src: local('Gotham LightItalic'), local('Gotham-LightItalic'), url('/assets/fonts/gotham/GothamLightItalic.ttf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham Book'), local('Gotham-Book'), url('/assets/fonts/gotham/Gotham-Book.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: 400;
  src: local('Gotham BookItalic'), local('Gotham-BookItalic'), url('/assets/fonts/gotham/GothamBookItalic.ttf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: local('Gotham Medium'), local('GothamMedium'), url('/assets/fonts/gotham/Gotham-Medium.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: local('Gotham Bold'), local('Gotham-Bold'), url('/assets/fonts/gotham/Gotham-Bold.otf');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 800;
  src: local('Gotham Black'), local('Gotham-Black'), url('/assets/fonts/gotham/Gotham-Black.otf');
}
