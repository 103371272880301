input:focus,
select:focus {
  border-color: #0080a3 !important;
  box-shadow: none !important;
}

input.is-warning {
  border-color: #947600 !important; /* bulma's has-text-danger-dark color */
}

.help.is-warning {
  color: #947600 !important; /* bulma's has-text-danger-dark color */
}

.help.is-danger::before,
.help.is-warning::before {
  content: "\f071";
  font-family: "Font Awesome 5 Free"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
  font-weight: 900;
  margin-right: 5px;
}
