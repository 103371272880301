$danger_color: #c72835;
$danger_darker_color: rgba(0, 0, 0, 0.3);
$lighter_color: rgba(255, 255, 255, 0.2);

.alert-message {
  &.alert-danger {
    color: #fff;
    background-color: $danger_color;
    font-size: 0.875rem;
  }

  .message-wrapper {
    padding: 12px 42px;
  }

  .darker {
    background-color: $danger_darker_color;
  }

  .lighter {
    background-color: $lighter_color;
  }

  .icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 18px;
    width: 18px;
    color: #fff;
  }

  .text {
    padding: 0 12px;
  }

  .message-child-box {
    padding: 7px 16px;
    border-radius: 4px;
  }

  .message-text-container {
    max-width: 997px;
    margin: auto;
  }

  .dismiss {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    cursor: pointer;
    color: #fff;

    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}

.toast-message-container {
  position: fixed;
  z-index: 39;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 24px);
  margin: auto;
  width: max-content;

  &.top {
    top: 77px;
    justify-content: flex-start;
  }

  &.bottom {
    bottom: 118px;
    justify-content: flex-end;
  }

  &.middle {
    top: 50%;
    transform: translate(-50%, 0);
  }

  &.right {
    right: 30px;
  }

  &.left {
    left: 30px;
  }

  &.center {
    left: 50%;
    transform: translate(-50%, 0%) !important;

    &.middle {
      transform: translate(-50%, 0%) !important;
    }
  }

  .toast-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: 12px 16px;
    margin-bottom: 10px;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 19px;
    font-family: Arial, sans-serif;
    background-color: rgba(55, 61, 69, 0.8);
    color: #fff;
    opacity: 1;
    transition: opacity 500ms ease 0s;

    &.hide {
      opacity: 0;
    }
  }
}
