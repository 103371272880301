.checkin {
  .media {
    &.clinician-card {
      padding: 16px;
      background-color: #f2f8f9;
      border-radius: 8px;

      .media-left {
        .avatar {
          height: 64px;
          width: 64px;
        }
      }
    }

    .v-logo-overlap {
      height: 32px;
      width: 32px;
      position: absolute;
      left: 86px;
      margin-top: 38px;
      z-index: 1;
    }
  }
}
