$primary: #0080a3;
$secondary: #17335b;
$white: #fff;
$turquoise: #00bbdc;
$button-color: #007cba;
$button-color-hover: #0090d9;
$light: #f8f8f9;
$warning: #e68842;
$danger: #c13c47;

$gap: 32px;
$mobile: 767px;
$tablet: 768px; // minus 1px for iPad mini
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);

$gray-5: #f0f2f3;
$gray-10: #e2e4e8;
$gray-20: #c6cbd1;
$gray-30: #abb1b9;
$gray-40: #9299a2;
$gray-60: #626a74;
$gray-70: #4c535c;
$gray-80: #373d45;
$gray-90: #24282e;

$navy-80: #455c7c;
$navy-90: #2e476b;

$green-success: #31ad6d;
$red-error: #d90012;
$bg-blue-30: #f2f8f9;

@function hoverColor($color) {
  @return lighten($color, 10%);
}

@function activeColor($color) {
  @return darken($color, 10%);
}
