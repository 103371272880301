@import "../../styles/variables.scss";

.modal {
  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    padding: 16px 46px 16px 46px;
    border-bottom: 1px solid #ccc;
    margin: -1.25rem -1.25rem 2rem -1.25rem;
    position: relative;

    .btn-close {
      cursor: pointer;
      position: absolute;
      right: 14px;
      bottom: 14px;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-content {
    padding: 0px 14px;
  }

  &.modal-full-screen {
    @media (max-width: $mobile) {
      .modal-background {
        background-color: #fff;
      }

      .modal-content {
        background-color: #fff;
        min-height: 100%;

        .box {
          min-height: 100%;
          box-shadow: none;
        }
      }
    }

    @media (min-width: $tablet) {
      .modal-background {
        background-color: #2f3b4d;

        &.transparent {
          background-color: rgba(47, 59, 77, 0.6);
        }
      }

      .modal-content {
        background-color: #2f3b4d;
      }

      .box {
        border-radius: 8px;
      }
    }

    .modal-content {
      width: 100%;
      max-height: 100vh;
      max-width: 444px;
      margin: 0;
    }

    &.white {
      .modal-content,
      .modal-background {
        background-color: #fff;
      }

      #modal-vmd-logo {
        display: none;
      }
    }
  }
}
