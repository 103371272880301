.ic-no-access {
  width: 102px !important;
  height: 82px;
  margin-bottom: 40px;
}

.back-button {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -16px;
  top: -16px;
  cursor: pointer;
  color: #979da6;
}
