@import "./variables.scss";

.button {
  &.is-primary {
    background-color: $primary !important;
    border-radius: 8px;
    color: $white;
    opacity: 1;
    box-shadow: none;
    filter: none;

    &:hover {
      background-color: #00a8c6 !important;
    }

    &:focus {
      background-color: #00a8c6 !important;
    }

    &:active {
      background-color: #004e71 !important;
    }

    &:disabled {
      background-color: $gray-5 !important;
    }
  }

  &.is-secondary {
    border-radius: 8px;
    background-color: $white !important;
    color: $primary;
    border: 0px;

    &:hover {
      background: $gray-5 !important;
    }

    &:focus {
      background: $gray-5 !important;
    }

    &:active {
      background: $gray-10 !important;
    }

    &:disabled {
      pointer-events: none;
      color: $gray-40 !important;
    }
  }

  &.is-danger {
    background-color: $danger;
    border-color: transparent;
    color: $white;

    &:hover {
      background-color: hoverColor($danger);
    }

    &:active {
      background-color: activeColor($danger);
    }

    &.is-inverted {
      background-color: $white;
      border-color: transparent;
      color: $danger;

      &:hover {
        color: $white;
        background-color: $danger;
      }

      &:active,
      &:focus {
        color: $white;
        background-color: activeColor($danger);
      }
    }
  }

  &.is-light {
    background-color: $gray-5;
    color: $primary;

    &:focus {
      background-color: $gray-5;
      color: $primary;
    }

    &:hover {
      background-color: rgba(0, 168, 198, 0.15);
      color: $primary;
    }

    &:active {
      background-color: rgba(0, 168, 198, 0.3);
      color: $primary;
    }
  }
  
  &.is-text {
    color: #24282e !important;
  }

  &:disabled,
  &.is-disabled {
    background: $gray-5;
    color: $gray-30;
  }
}
