@import "../styles/variables.scss";

.boxed-page {
  background-color: #2f3b4d;
  display: flex;
  flex-direction: column !important;
  position: relative;
  min-height: 100%;
  height: 100%;

  #boxed-vmd-logo {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }

  .boxed-container {
    position: relative;
    flex-grow: 1 !important;
    overflow-y: scroll;

    .boxed-centered {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      height: 100%;
    }

    .boxed-content {
      position: relative;
      width: 100%;
      max-height: 100vh;
      max-width: 460px;
      margin: 0;

      .boxed-content-padding {
        padding: 40px 0px;
      }
    }

    .box {
      border-radius: 8px;
    }

    @media (max-width: $mobile) {
      .boxed-content {
        max-width: 100%;
        max-height: 100%;
      }

      .boxed-content,
      .boxed-content-padding,
      .box {
        border-radius: 0;
        height: 100%;
      }

      .boxed-content .boxed-content-padding {
        padding: 0px;
      }
    }
  }
}
