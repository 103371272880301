button.is-primary,
.button.is-primary {
  background-color: #00bbdc !important;
  border-radius: 4px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

.button.is-primary:hover {
  background-color: #00bbdccc !important;
  color: white;
}
